import React from "react";
import { Link } from 'react-router-dom';
import './early-life.css';

const EarlyLife = () => {

          return(
            <div>

              <div className='blue top info'>
              <h2>Early Life and Whaling</h2>                
                <p>
                 Emil Hvaal was born on the 27th September 1908 at a farm called 'Dalen' near Svarstad in Eastern Norway, to Laurits Hvaal and his wife Nilsine, the third of their eleven children. 
                 Their surname, Hvaal, (pronounced 'vaal') like many Norwegians at the time, came from the area where they lived and was a group of farms in that area.
                At this time, more of the population would have lived in the countryside than in the cities.
                'Substinence farming' would have been a way of life for many, producing enough to live on with little surplus. 
                All the family would have been expected to work, even the younger members.
                </p>
                <p>
                After growing up at 'Dalen', he and his older brother, Lars, went to work on another nearby farm.
                The days would have been long and arduous. A typical day on a farm in those times could last from 6am in the morning to 7pm at night. 
                However, his time on the farm wouldn't last and in 1925, Emil had to search for work elsewhere, the place he chose to look would be close to home, but would take him on a journey to the other side of the world.
                <sup><a aria-label='reference 1' href='#talknorway'>[1]</a></sup>  
                </p>
                <div className='learn-more'>
                <p>
                  Read more about farming life in Norway:
                </p>
                <a className='p-link' aria-label='Norway and its age old farming culture' target='_blank' href='https://talknorway.no/norway-and-its-age-old-farming-culture/'>Norway and its age old farming culture</a>
                </div>
                <figure>
                 <img alt='' src='https://live.staticflickr.com/65535/52191695209_53cda6c1f8_o.jpg'></img>
                 <figcaption>The river which runs next to 'Dalen'.</figcaption>
                </figure>
              </div>
              <div className='red info' >
                <p className='top'>
                  Tonsberg, a town on the eastern coast of Norway, has a long history of seafaring dating back to the Vikings. 
                  A Viking ship, <a target='_blank' aria-label='oseberg' href='https://sagaoseberg.com/eng/?utm_source=visitnorway.com&utm_medium=listing&utm_content=visit-website-link'>Oseberg</a>, one of the most important discoveries from these times was found here in 1904.
                  The Vikings were heavily reliant on the sea, including one of its most unique creatures, the whale. 
                  They would have hunted them in rowboats with spears and little would have changed over the next few hundred years. 
                  This would be the case until the middle of the 19th century when this changed significantly due to one man. 
                  
                </p>
                <p>
                  Svend Foyn was born in Tonsberg in 1809 and by age 11 he was already working on ships.
                  In 1870 he patented his grenade harpoon gun, which would fire a harpoon with a head that contained modern explosives and would explode on impact. 
                  He also built the first steam-powered whale-catcher, <i>Spes Et Fides</i>.
                  These innovations would revolutionize the industry as it meant larger whales could be hunted and increased the efficiency of whaling. 
                  This caused Tonsberg to become one of the centres of the world in this industry. 
                  </p>
                  <p>
                  He later funded an expedition of Antarctica in 1893-1894 led by Henrik Johan Bull, a businessman from Tonsberg. 
                  Among the crew, was Carsten Borchgrevink, from Oslo, who was one of the first people to set foot on the Antarctic mainland and later led many polar expeditions. 
                </p>
                <div className='learn-more'>
                <p>
                Learn more about Tonsberg and Svend Foyn:
                </p>
                <a className='p-link' target='_blank' aria-label='Slottsfjellmuseet' href='https://vestfoldmuseene.no/slottsfjellsmuseet/?utm_source=visitnorway.com&utm_medium=listing&utm_content=visit-website-link'>Slottsfjellmuseet</a>
                </div>
                <figure>
                  <img alt='' className='portrait' src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/BULL%281896%29_p027_COMMANDER_SVEND_FOYN.jpg/623px-BULL%281896%29_p027_COMMANDER_SVEND_FOYN.jpg?20140801115356'></img>
                  <figcaption>Svend Foyn, 1896</figcaption>
                </figure>
              </div>
              <div className='blue info'>
                <p className='top'>
                  Whales migrate to polar regions in the summer due to an increase in krill caused by melting ice releasing nutrients into the sea. 
                  There had been some whaling in Antarctica since at least the early 19th century, but there was little need to make the difficult journey south as there was still plenty of whales in the Arctic.
                  However, due to overhunting, the whale population in the Arctic had significantly reduced by the end of the century and the industry decided to look for new hunting grounds as well as a new base for these operations. 
                  </p>
                  <p>
                  Carl Anton Larsen was an Antarctic explorer and whaler from Ostre Halsen near Larvik in eastern Norway. 
                  He led a whaling expedition in 1892-1893 to Antarctica which discovered fossils there, the first from the mainland.
                  The next year he led the same ship that was part of a three ship expedition where he discovered Foyn Coast in Antarctica, naming it after Svend Foyn. 
                  After the expedition, the ships regrouped in South Georgia. 
                  Larsen decided that this would be an ideal base for whaling. 
                  </p>
                  <p>
                  In November and December 1904 he oversaw the construction of the first whaling station on South Georgia by 60 Norwegians, which was named Grytviken and where his business Compania Argentina de Pesca would operate.
                </p>
                <figure>
                  <img alt='' src='https://live.staticflickr.com/65535/31984270070_2d681de36f_w.jpg'></img>
                  <figcaption>Grytviken church, built 1913</figcaption>
                  <p>(Owner: Rob Oo, <a href='https://creativecommons.org/licenses/by/2.0/'>Licence)</a></p>
                </figure>
              </div>
              <div className='red info'>
                <p className='top'>
                  In 1923, Emil's cousin Nils Dalen, who was living in Svarstad at the time, received the following card from South Georgia:  
                </p>
                <div className='italics'>
                <p>
                <i>
                  I am sending you a card, so you can see how different it is down here. 
                  And how a whale looks like.
                  They are a little bit bigger than the ones you are fishing in the river. 
                  I have it just fine, and I hope it is the same at your home.
                </i>
                </p>
                </div>
                <p>
                  It is unclear who had sent this to him, although it is believed that others were also sent. 
                  As Svarstad was a small place and the family is believed to have been close, it is likely that Emil would have seen this or another of the cards, perhaps encouraging Emil and his cousin to also make the journey.  
                </p>
                <figure>
                    <img src='https://live.staticflickr.com/65535/52465041400_2a57100855_z.jpg'></img>
                    <figcaption>The postcard sent to Emil's cousin, Nils Dalen</figcaption>
                </figure>
              </div>
              <div className='blue info'>
                <p className='top'>
                  In 1925, Emil walked to Tonsberg to get work in the whaling industry in South Georgia.  
                  He would live in Tonsberg and make this whaling trip every year until 1940, apart from time spent doing his military service in 1929, training as a radiotelegraphist in 1931 and marrying his first wife Kamilla in 1934.
                  One of the companies he worked for was Christian Salvesen Ltd., travelling on their ships from his home in Tonsberg to Leith Harbour, their base in South Georgia. 
                </p>
                <p>
                  Salve Christian Frederik Salvesen was born in 1827 in Mandal, Norway, the son of Thomas Salvesen, the owner of a shipping business. 
                  His older brother Johan Theodor Salvesen set up Salvesen & Turnbull with George Turnbull and opened an office in Leith, Scotland in 1846. 
                  Christian Salvesen later joined his brother there in 1851, where he would set up his own shipping business, Christian Salvesen Ltd., in 1872.
                  The company grew to operate throughout the Arctic.
                  It wasn't until the company had passed to his two sons, Thomas and Frederick, in 1883, that the interest in Antarctic whaling grew.
                  </p>
                  <p>                  
                  Henrik Henriksen was from a family of blacksmiths based in Tonsberg who had reportedly worked with Svend Foyn. 
                  He was instrumental in the operations of Salvesen's in the Arctic and in 1908 he was appointed to set up the whaling station of Leith Harbour in South Georgia, becoming the manager there until 1916.               

                </p>
                <div className='learn-more'>
                  <p>
                    Learn more about Christian Salvesen:
                  </p>
                  <a aria-label='salvesen archive' href='https://libraryblogs.is.ed.ac.uk/edinburghuniversityarchives/2019/05/08/salvesen-archive-50-years-at-edinburgh-university-library-1969-2019/'>Salvesen Archive</a>
                </div>
                <figure>
                  <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/a/a7/Whaler_leith_south_georgia.jpg?20140319113034'></img>
                  <figcaption>A whaler in the workshop in Leith Harbour</figcaption>
                </figure>
                </div>
                <div className='red info'>
                <p className='top'>
                  South Georgia and its financial organisation was overseen by the British from the Falkland Islands as one of its dependencies.
                  Due to increased regulation and taxation on the island, Salvesen's fitted two of their ships with stern slipways so the whale carcasses could be brought on board to be processed. 
                  These two ships were the Salvestria and Sourabaya, both of which Emil worked on. 
                  They would have worked with catcher ships to hunt the whale, and his role as a radiotelegraphist would have been an important one to communicate between them. 
                  They would have done this in code to avoid ships from other companies becoming aware of the presence of the whales they were hunting. 
                </p>
                <p>
                 The BBC did a very interesting two part programme about whaling in 2014 called 'Britain's Whale Hunters: The Untold Story', which has many first hand accounts from British whalers who worked in South Georgia and Antarctica. 
                 Below is a link to the programme website, the full programme is no longer available, however thre are many clips which explain what life would have been like. 
                </p>
                <div className='learn-more'>
                <a target='_blank' aria-label='Britains Whale Hunters: The Untold Story' href='https://www.bbc.co.uk/programmes/b046pbk9/clips'>Britain's Whale Hunters: The Untold Story</a>
                <p>
                  The BBC also did an interview with one of these whalers, Gibbie Fraser, which you can listen to here: 
                </p>
                <a target='_blank' aria-label='Witness History: The Antarctic Whale Hunters' href='https://www.bbc.co.uk/sounds/play/w3cswsp5'>Witness History: The Antarctic Whale Hunters</a>
                </div>
                <figure>
                  <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/0/0a/Salvestria_and_Sourabaya.jpg?20090402132425'></img>
                  <figcaption>The Salvestria and Sourabaya docked in Tonsberg</figcaption>
                </figure>
              </div>
              <div className='blue info'>
                <p className='top'>
                  By the 1960s, the population of whales in Antarctica had significantly reduced and most of the whaling stations on South Georgia closed during this time. 
                  Many of the men who had worked on the ships made this decision as there was little employment where they had come from, however it had been a difficult way of life with long periods of time away from home in cramped conditions. 
                  Since the 1960s, there have been several conservation efforts to increase the number of whales. 
                  One of these is the Friends of South Georgia Island:
                </p>
                <div className='learn-more'>
                <a target='_blank' aria-label='The Friends of South Georgia - Whaling' href='http://www.fosgi.org/about-south-georgia/history/whaling/'>The Friends of South Georgia - Whaling</a>. 
                </div>
                <figure>
                  <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/6%2813%29_Sperm_whale.JPG/1200px-6%2813%29_Sperm_whale.JPG?20130128225144'></img>
                </figure>
              </div>
                <a className='next-button' href='/RN-and-SOE-training'>Next Page</a>
              <div className='red references'>
                <h2>References</h2>
                <p id='talknorway'>[1] Talk Norway, <i>Norway and its age old farming culture</i>, viewed June 2022, https://talknorway.no/norway-and-its-age-old-farming-culture/</p>
             </div>

            </div>

        )
    }

export default EarlyLife ;