import React from "react";
import { Link } from 'react-router-dom';
import './My-Fathers-Story.css'; 

const MyFathersStory = () => {

          return(
            
            <div className='blue top info myfathersstory'>
              <h2>After a DNA test I discovered that my Father was a Norwegian resistance fighter in WWII.</h2>                
<p>
My mother was born in 1921 in the East End of London, the family lived in a large house near the docks. They lived on one floor with other families living in the house, overcrowded grim conditions. Her father was seriously injured during the First World War and came back as a cripple with half a leg missing on a meagre war pension. His brother, my great uncle was captured by the Germans and lost his life as a prisoner in 1918, he was 31 years old. My Grandfather died in 1938 aged 59 leaving behind a widow with an 8 year old son and my 17 year old Mother. Soon after my grandmother then decided to move to Southend-on-Sea in Essex, with her young son away from the docks of East London. My mother was then left on her own until she was eventually called up as a single woman over 18 to help the Government war effort.  She finished up working at the Pye radio factory in Cambridgeshire. 
</p>
<p>
My biological father Emil Hvaal was a Norwegian, working as a radio operator on a whaler. When the Germans took over Norway, they made their way back to London to form a Free Norwegian group. Emil was then recruited by the SOE to train as a secret agent for subversive activities against the enemy. After that he changed his name and identity and travelled to various secret service places in UK, and Emil met my mother and the magic of the DNA database pinpointed that Emil was my biological father. When she found out that she was pregnant, my Mother was told that he had gone overseas to fight in the war and later on she was told that he would not be coming back. I was born in September 1942 in a convalescent home in Bath, Somerset.
</p>
<p>
Emil did go back to Norway several times via the Shetland bus taking arms and munitions with them and bringing back refugees.
</p>
            </div>
        )
    }

export default MyFathersStory ;