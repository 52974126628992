import React from "react";
import { Link } from 'react-router-dom';
import './POW-and-war-memorials.css'; 

const POWMemorials = () => {

          return(
            
            <div >

              <div className='blue top info'>
              <h2>POW and War Memorials</h2>                
                <p >                  
                After being seriously injured and captured, Emil was taken to Haukeland hospital in Bergen by the Nazis to be treated so he could then be tortured and interrogated. 
                On 4th May 1942, the state police informed his wife Kamilla that Emil was at the hospital and that he was dying. 
                The last she had heard from him was a telegram in 1939 and didn't know anything about what had happened at Televag, so found the message to be rather strange but decided to travel to Bergen regardless.
                When she got to the Gestapo HQ in Bergen, she was informed that Emil had 'shot the best two Gestapo we had'. 
                She was kept there and interrogated by the Gestapo, who wanted to use her to get information from Emil, as he was refusing to admit shooting the two Gestapo in Televag.
                When she visited him at the hospital, she told him 'Vaer Norsk, jeg stoler pa deg' - 'Be Norwegian, I trust you'. 
                This would be the last time she saw him.
                <sup><a aria-label='reference 1' href='#aksjontelevag'>[1]</a></sup>  
                </p>
                <p>                
                Ulven camp, around 30km from Bergen, was a military training camp before the war, however after the German occupation it was used as a concentration camp. 
                Later in 1942, after his time in hospital, Emil was taken there where he was held as a POW with Lars Telle, both of them being kept in solitary confinement.
                During their time there, they were both also tortured and interrogated.
                </p>
                <p>
                Arkivet Peace and Human Rights Centre is based in a building in Kristiansand in southern Norway which from 1942 - 1945 was the regional headquarters for the Gestapo, where they carried out torture and interrogation in the basement.
                They have exhibitions on the Norwegian resistance and the Gestapo, in the hope that by explaining what happened, it will promote peace and democracy. 
                The following is a link to one of their web pages describing what took place in the basement during that time:
                </p>
                <div className='learn-more'>
                <a target='_blank' aria-label='arkivet - the torture' href='https://arkivet.no/en/history/during-the-war/the-torture'>Arkivet</a>
                </div>
                <figure>
                    <img alt='' src='https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Soldiers_at_Ulven_camp.jpg/1184px-Soldiers_at_Ulven_camp.jpg?20210317002205'></img>
                    <figcaption>Ulven camp, c. 1936-1937</figcaption>
                </figure>
              </div>
              <div className='red info'>
                <p className='top'>
                    On 14th September 1942, Emil was able to send a letter back to his family from Ulven, the only writing from Emil known to exist, the English translation is below: 
                </p>
                <div className='italics'>
                <p>
                <i>
                Dear parents and siblings,
                </i>
                </p>
                <p>
                <i>
Hope you get this letter as it is the first you hear from me in years. I am under the circumstances quite well even though I am longing terribly for home. I have done what I can so now the rest will be left to our master, hope it is corrected as time goes on.
</i>
</p>
<p>
<i>
As you probably know, Camilla was here this spring and I talked to her a couple of times.
I have since received four letters while I myself have sent one. She says in the last letter that she should go on a three-day holiday and a trip to Svarstad, so maybe she's with you now. Heard that Emma
and Sigmund has married, you get to greet them so much. I have not seen them since
when they cycled to Germany. It's like it's been forever since I saw you all
together.
</i>
</p>
<p>
<i>
Hope you are healthy and well, we will probably meet again sooner or later. We get to layer ourselves
with patience and live in hope. Yes, you're probably doing autumn work now, that's right
been late this year but now the weather may be better. I wish I could have been involved and done
a little too. Hope to hear from you soon and the address on the back of the envelope
is enough.
</i>
</p><p>
<i>
The 25th, by the way, I'm 34 years old, amazing but true, and you can send a birthday cake, I'm not saying no.
</i>
</p><p>
<i>
I have addressed this letter to all of you as I can not write more than one at a
time, and if Camilla has not already been home she will probably come one of these days.
Arne and Ruth still live in Kongsberg, don't they? You get to greet them so much from me and that
same to Berg and Hvaal.
</i>
</p><p>
<i>
Finally, a heartfelt greeting to mother and father and all of you in Dalen and Svarstad in the hope of
to be reunited by the warmth of home in the not too distant future. God bless you
all. 
</i>
</p><p>
<i>
Yours Emil
                </i>
                </p>
                </div>
                <figure>
                    <img alt='' className='portrait' src='https://live.staticflickr.com/65535/52143703111_317d874757_k.jpg'></img>
                    <figcaption>The original letter</figcaption>
                </figure>
              </div>
              <div className='blue info'>
                <p className='top'>
                In February 1943, Emil and Lars Telle were transferred to Grini concentration camp. 
                Grini, near Oslo, was a women's prison before the war, but was used by the Germans from June 1941 as a concentration camp, the largest in Norway. 
                The conditions would have been difficult, but not as severe as those in camps such as Auschwitz or Dachau.  
                The prisoners would have been able to live under their own management under German authority.
                As the numbers at the camp increased, the prisoners had to build extra accomodation.
                The camp contained Norwegians from all parts of society. 
                <sup><a aria-label='reference 1' href='#grini'>[2]</a></sup> 
                </p>
                <p>
                  One of these included Odd Nansen, the son of polar explorer and humanitarian, Fridtjof Nansen, who led the first crossing of Greenland in 1888. 
                  Before the war, Odd Nansen had been an architect, however due to his work in the resistance as well as assisting Jewish refugees in central Europe he was imprisoned by the Gestapo in several concentration camps.
                 </p>
                 <p> 
                  During this time, he wrote a diary of his experiences, being able to keep it secret, despite the rigorous searches that the prisoners faced when moved between camps.
                  One method for keeping it secret included in a hollowed out breadboard that each prisoner was given to carry food.
                  After the war, a book was made, From Day to Day, based on the diary, with two chapters describing his time at Grini, one from January 13th 1942 to August 4th 1942 and the other from November 22nd 1942 to October 6 1943.
                  Below is a link to the book, which includes a further description as well as a preview:   
                  <sup><a aria-label='reference 2' href='#nansen'>[3]</a></sup>  
                </p>
                <div className='learn-more'>
                <a target='_blank' aria-label='from day to day' href='https://www.vanderbiltuniversitypress.com/9780826521002/from-day-to-day/'>From Day to Day </a>
                </div>
                <figure>
                  <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/4/45/Den_siste_appell_p%C3%A5_Grini_fangeleir%2C_8._mai_1945%2C_Oslo_Museum%2C_OB.F12570c_-_Crop.jpg'></img>
                  <figcaption>Grini concentration camp</figcaption>
                  <p>(Owner: Fanny Wikborg, Oslo Museum, <a aria-label='licence' href='https://creativecommons.org/licenses/by-sa/4.0/deed.en'>licence</a>)</p>
                </figure>
              </div>
              <div className='red info'>
                <p className='top'>
                  The following excerpt is Odd Nansen's diary entry for 1st May 1942 and describes the punishment carried out by the Nazis at Grini for the incident at Televag:
                </p>
                <div className='italics'>
                  <p>
                <i>
                They were shot yesterday morning at ten o'clock, no trial,
no red tape. After the Storm Prince's philippic they were driven away, probably straight to
Hovedoyat or Akershus. Eighteen brave Norwegian lads are no more. Eighteen atrocious
murders that cry for vengeance. Vengeance! I am afraid that one day vengeance will be
executed and that no one can prevent its happening. No one can prevent it. God help us

all.
                
</i>
</p><p>
<i>
                It was on the radio at half-past seven this morning. Some prisoners had been listening
in the Vermittlung guardroom, and it spread at once through the camp. They were shot
as a reprisal for two Gestapo men who were killed somewhere in Vestland a few days ago
while engaged on their “lawful” work. The name of every man had been read out; each
was like a whiplash, like a stab in the heart, the names of comrades with whom we had
been living for weeks, had shared food and tobacco, had joked and laughed, had discussed
the situation, theirs and our own, the country’s and the world’s. Comrades we had grown
attached to, a rare band of stout Norwegians, all between twenty and twenty-five years
old. They were shot down at ten o'clock yesterday.
</i>
</p><p>
<i>
                If one could only cry one’s fill over it; but even that is impossible. There’s nobody to go
to and cry, not even a little solitary place with a tree, a flower, or a little animal to confide
in, a place where one could sit down alone and collect some poor bit of strength.
                
                </i>
                </p>
                </div>
                <p className='attribution'>
                Attribution: Nansen, Odd. 2016. From Day to Day: One Man’s Diary of Survival in Nazi Concentration Camps. Edited by Timothy J. Boyce. Courtesy of Vanderbilt University Press
                </p>
                <p>
                  Throughout the use of Grini as a concentration camp, several POWs would be taken to a nearby forest, Trandumskogen, and executed. 
                  This fate would await Emil and Lars Telle, as well as eight other Norwegians involved in the resistance, on one day in October 1943, Emil's SOE records reporting this as the 19th October 1943. 
                </p>
                <p>
                  It wouldn't be until after the German surrender in May 1945 that what had happened here was discovered. 
                  In total 173 Norwegians, 15 Russians and 6 Brits were found in a mass grave in the forest.
                  Leading members of Nasjonal Samling, the Norwegian and Nazi imposed ruling party during the war, as well as its founder and leader, Vidkun Quisling, were taken to the site to exhume the grave. 
                  This was done to acknowledge that due to their backing of the Nazis, thay had been complicit in war crimes, and that by witnessing what had happened it wouldn't be forgotten. 
                  <sup><a aria-label='reference 3' href='#newsreels'>[4]</a></sup>                
                
                </p>
                <p>
                  On 10th October 1954, a memorial was unveiled on the site by Crown Prince Olav of Norway. 
                  The following text is carved onto the memorial on three sides in Norwegian, Russian and English:
                  
                </p>
                <div className='italics'>
                  <p>
                <i>
                "In the fight for freedom during the 1940-1945 war, 173 Norwegians, 15 Soviet subjects and 6 Britons were executed here by the enemy in the woods of Trandum."
                </i>
                </p>
                </div>
                <p>
                  In 1970, a memorial wall was unveiled, engraved with the names of the people who were buried there.
                  18 numbered stone crosses also mark where the grave sites were found. 
                </p>
                <figure>
                          <img alt='' className='portrait' src='https://live.staticflickr.com/2362/3532066442_067757377a_c.jpg'></img>
                          <figcaption>Trandumskogen Monument</figcaption>
                          <p>(Owner: Fredrik Olastuen, <a aria-label='licence' href='https://creativecommons.org/licenses/by-nc/2.0/'>licence</a>)</p>
                </figure>
                <figure>
                  <img alt='' src='https://live.staticflickr.com/2249/3531176943_d3c46d5212_b.jpg'></img>
                  <figcaption>Trandumskogen Memorial Wall</figcaption>
                  <p>(Owner: Fredrik Olastuen, <a aria-label='licence' href='https://creativecommons.org/licenses/by-nc/2.0/'>licence</a>)</p>                  
                </figure>
                <figure>
                  <img alt='' className='portrait' src='https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Trandumskogen_grav_nr1.jpg/608px-Trandumskogen_grav_nr1.jpg?20070903170106'></img>
                  <figcaption>Trandumskogen Memorial Stone</figcaption>
                  <p>(Owner: Tommy Gildseth, <a aria-label='licence' href='https://creativecommons.org/licenses/by/3.0/deed.en'>licence</a>)</p>
                </figure>
              </div>
              <div className='blue info'>
                <p className='top'>
                  After the war, many memorials were erected to remember those who served in the war and those who lost their lives. 
                  The following briefly describes a few of these, however there are many more and it wouldn't be possible to cover all of these in one website. 
                </p>
                <p>  
                  There is a memorial at Glenmore in the Cairngorns in Scotland for the Kompani Linge agents, enscribed on a metal plaque on the stone is the text below: 
                  
                </p>
                <div className='italics'>
                <p>
                <i>
                  Kompani Linge
                </i>
                </p>
                <p>
                <i>You opened your homes and your hearts to us and gave us hope.</i>
                </p><p>
                <i>
                This stone was erected by the people of Badenoch in honour of the gallant company of Norwegian patriots who lived among them & trained in these mountains 1941-1945 to prepare for operations in occupied Norway. 
                By skilful & daring raids on military & industrial targets they harassed the enemy & denied them vital supplies. 
                These dangerous missions were not carried out without losses, 37 brave men of Kompani Linge gave their lives in our common cause.
                </i>
                </p>
                </div>
                <p>
                  The following is a link to a BBC article which explains more about the memorial and the Kompani Linge agents:
                </p>
                <div className='learn-more'>
                <a target='_blank' aria-label='Scottish tribute to soldiers who foiled Nazis A-bomb' href='https://www.bbc.co.uk/news/uk-scotland-highlands-islands-37846376'>Scottish tribute to soldiers who foiled Nazis' A-bomb</a>
                </div>
                <figure>
                  <img alt='' src='https://s0.geograph.org.uk/photos/99/14/991410_16d75933.jpg'></img>
                  <figcaption>Kompani Linge Memorial in Glenmore</figcaption>
                  <p>(Owner: Ronnie Leask, <a aria-label='licence' href='https://creativecommons.org/licenses/by-sa/2.0/'>licence</a>)</p>
                </figure>
              </div>
              <div className='red info'>
                <p className='top'>
                There is also a memorial to members of Kompani Linge agents and the Shetland Bus next to Akershus Fortress, in Oslo. 
                The memorial lists the names of the agents who lost their life.
                There is also a museum to the Norwegian resistance near the fortress: 

                </p>
                <div className='learn-more'>
                <a target='_blank' aria-label='Norways Resistance Museum' href='https://www.visitnorway.com/listings/norways-resistance-museum/2764/'>Norway's Resistance Museum</a>
                </div>
                <figure>
                  <img alt='' className='portrait' src='https://upload.wikimedia.org/wikipedia/commons/7/70/Norwegian_Independent_Company_1_and_Shetland_bus_memorial_Akershus_Fortress_%282014%29.jpg'></img>
                  <figcaption>Kompani Linge and Shetland Bus Memorial</figcaption>
                  <p>(Owner: Manxruler, <a aria-label='licence' href='https://creativecommons.org/licenses/by-sa/4.0/'>licence</a>)</p>
                </figure>
              </div>
              <div className='blue'>
                <p className='centre top'>
                  Martin Linge died during WWII in <a target='_blank' aria-label='operation archery' href='https://www.combinedops.com/vaagso.htm'>Operation Archery</a> on 27th December 1941, there are several memorials in his honour. 
                </p>
                <figure>
                  <img alt='' className='portrait' src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Martin_Linge_memorial.JPG/632px-Martin_Linge_memorial.JPG?20130714193710'></img>
                  <figcaption>Martin Linge memorial at Linge farm, Valldal, Norway</figcaption>
                  <p>(Owner: Jygre, <a aria-label='licence' href='https://creativecommons.org/licenses/by-sa/3.0/deed.en'>licence</a>)</p>
                </figure>
              </div>
              <div className='red info'>
                <p className='top centre'>
                  A memorial was erected in Televag to the villagers who died during the war. 
                </p>
                <figure>
                  <img alt='' className='portrait' src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Telavaag_-_Minnestein_monument_1940-1945_d%C3%B8de_i_fangenskap_%28WWII_memorial%29_2017-10-23_a.jpg/674px-Telavaag_-_Minnestein_monument_1940-1945_d%C3%B8de_i_fangenskap_%28WWII_memorial%29_2017-10-23_a.jpg?20171023212737'></img>
                  <figcaption>Televag Memorial</figcaption>
                  <p>(Owner: Wolfmann, <a aria-label='licence' href='https://creativecommons.org/licenses/by-sa/4.0/deed.en'>licence</a>)</p>
                </figure>
              </div>
              <div className='blue info'>
                <p className='top'>
                A museum and memorials were built at the site of Grini concentration camp.
                The museum has exhibitions about prison life and first hand accounts from the prisoners.
                </p>
                <div className='learn-more'>
                <p>
                  Link to the museum website:
                </p>
                <a target='_blank' aria-label='grinimuseet' href='https://mia.no/grinimuseet/english'>Grinimuseet</a>
                <p>Link to a web page about the memorials there: </p>
                <a target='_blank' aria-label='Norways Unbroken. Lauritz Sand. Grini, World War II' href='http://norwayroadways.blogspot.com/2015/01/norways-unbroken-lauritz-sand-grini.html'>Norway's Unbroken. Lauritz Sand. Grini, World War II</a>
                </div>
                <figure>
                  <img alt='' src='https://live.staticflickr.com/2445/4015969311_d0e2825e63_b.jpg'></img>
                  <figcaption>Grini Museum</figcaption>
                  <p>(Owner: Baerum Kommune, <a aria-label='licence' href='https://creativecommons.org/licenses/by-nc/2.0/'>licence</a>)</p>
                </figure>
              </div>
              <div className='red info'>
                <p className='top'>
                  A memorial was also unveiled in Svarstad, Emil's home town, to the people of the village who had died during the war. 
                  Many of the names are of young men who served on ships that were sunk by torpedo, Emil's name is second from the top.
                </p>
                <figure>
                  <img alt='' src='https://live.staticflickr.com/7379/27178130035_0128ca0c1e_h.jpg'></img>
                  <figcaption>Svarstad memorial</figcaption>
                  <p>(Owner: <a target='_blank' aria-label='licence' href='https://www.flickr.com/people/mrpb27/'>Ken</a>)</p>
                </figure>
              </div>
              <div className='blue references'>
                <h2>References</h2>
                <p id='aksjontelevag'>[1] Anrfinn Haga, 1993, <i>Aksjon Televag</i>, Cappelen Damm</p>
                <p id='grini'>[2] WWII Norge, <i>Grini</i>, viewed June 2022, <a target='_blank' aria-label='Grini' href='https://www.wwiinorge.com/notes/grini/'>https://www.wwiinorge.com/notes/grini/</a></p>
                <p id='nansen'>[3] Tim Boyce, <i>Odd Nansen and his Secret Diary</i>, viewed June 2022, <a target='_blank' aria-label='odd nansen and his secret diary' href='https://www.timboyce.com/pdf/VikingNansen.pdf'>https://www.timboyce.com/pdf/VikingNansen.pdf</a></p>                
                <p id='newsreels'>[4] Ciara Chambers, E 2018, <i>Researching Newsreels, Local, National and Transnational Case Studies </i>, Springer International Publishing</p> 
              </div>
            </div>
        )
    }

export default POWMemorials ;