import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import Header from './components/header/header'; 
import Home from './components/home/home';
import EarlyLife from './components/early-life/early-life';
import RNSOE from './components/RN-SOE/RN-SOE';
import Televag from './components/televag/televag'; 
import POWMemorials from './components/POW-and-war-memorials/POW-and-war-memorials';
import MyFathersStory from './components/My-Fathers-Story/My-Fathers-Story'; 
import DisqusComments from './components/comments/disqusComments'; 
import Footer from './components/footer/footer'; 

function App() {
  return (
    <Router>
    <Routes>
      <Route exact path = '/' element={<div > <Header/> <Home /> <DisqusComments /> <Footer /> </div>}/>        
      <Route exact path = '/early-life-and-whaling' element={<div><Header/> <EarlyLife /> <DisqusComments /> <Footer /></div>}/>       
      <Route exact path = '/RN-and-SOE-training' element={<div><Header/> <RNSOE /> <DisqusComments /> <Footer /></div>}/> 
      <Route exact path = '/Televag' element={<div><Header/> <Televag /> <DisqusComments /> <Footer /></div>}/> 
      <Route exact path = '/POW-and-war-memorials' element={<div><Header/> <POWMemorials /> <DisqusComments /> <Footer /></div>}/>   
      <Route exact path = '/my-fathers-story' element={<div><Header/> <MyFathersStory /> <DisqusComments /> <Footer /></div>}/>       
    </Routes>
  </Router>
  );
}

export default App;
