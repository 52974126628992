import {DiscussionEmbed} from "disqus-react"; 
import './comments.css';

const DisqusComments = ({ post }) => {
    const disqusShortname = "emilgustavhvaal-com"
    const disqusConfig = {
      url: "https://emilgustavhvaal.com",
      identifier: 'article-id', // Single post id
      title: 'Title' // Single post title
    }
    return (
      <div className='blue comments'>
        <h2>Share your thoughts:</h2>
        <p>Email: davisc1@mail.com</p>
        <DiscussionEmbed className='disquscomments'
          shortname={disqusShortname}
          config={disqusConfig}
        />
      </div>
    )
}

export default DisqusComments;
