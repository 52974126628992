import React from "react";
import { NavLink } from 'react-router-dom';
import './header.css'; 

const Header = () => {

          return(
              <div className='header'>
                <div className='blue-cross'>
                </div>
                <div className='header-text'> 
                <div className='emil'>
                <h1>Emil Gustav Hvaal</h1>
                <p>27.09.1908 - 19.10.1943</p> 
                <p >'I hope that my husband got to do a little for his country as he was so eternally in love with it' - Camilla Hvaal, 1945</p>
                             
                </div>
                <div className='white-cross'></div>
                <div className='nav'>
                 <div><NavLink to='/' className='NavLink' >Home</NavLink>
                 </div>
                 <div>
               <NavLink to='/early-life-and-whaling' className='NavLink'>Early Life and Whaling</NavLink>
               </div>
               <div>
               <NavLink to='/RN-and-SOE-training' className='NavLink'>RN Service & SOE Training</NavLink>
               </div>
               <div>
               <NavLink to='/Televag' className='NavLink'>The Televag Tragedy</NavLink>
               </div>
               <div>
               <NavLink to='/POW-and-war-memorials' className='NavLink'>POW and War Memorials</NavLink>
               </div>
               <div>
               <NavLink to='/My-Fathers-Story' className='NavLink'>My Father's Story</NavLink>
               </div>
                </div>
                </div>

              </div>

        )
    }

export default Header ;