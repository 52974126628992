import React from "react";
import { NavLink, Link } from 'react-router-dom';
import './home.css';

const Home = () => {

          return(
            <div>
               
               <div className = 'blue info'>
                   <p className='top'>
                       Emil Hvaal served as part of the Norwegian resistance during WWII and lost his life like many others at the time, fighting for freedom and democracy. This meant that he wasn't able to tell his story and hopefully this website goes a small way to doing that. 
                   </p>
                   <p>
                       He was always part of a team whether that was as a whaler, in the Royal Navy, or the SOE and working as a group would have been of high importance, making the difference in challenging circumstances, so there are also first hand accounts from others who would have shared similar experiences. 
                       There are other websites that already have very detailed and informative explanations of some of these topics and links to these have also been provided. 
                       
                   </p>
                   <figure>
                     <img alt='emil hvaal' className='portrait' src='https://live.staticflickr.com/65535/52122132305_115bed633c_c.jpg'></img>
                     <figcaption>Emil Hvaal (colourised black & white photo)</figcaption>
                   </figure>
               </div>

                 <div className=' red link info'>
                  <h2 className='top'>Early life and whaling</h2>
                   <p >
                       Emil was born and brought up on a farm in eastern Norway, but after deciding to walk to a local town to get work, spent several years on board a 'floating factory' in Antarctica. 
                       In this section, you can learn more about why many people from this area of Norway made this journey to the other side of the world and what life was like when they got there.
                   </p>


                  

                                 
                   <figure>



                    <img alt='dalen' src='https://live.staticflickr.com/65535/52461187940_c46395d4d2.jpg'></img>

                    <figcaption>Dalen, the farm where Emil grew up.</figcaption>
                   </figure>
                 </div>
               

                  <div className='blue link info'>
                    <h2 className='top'>Royal Navy and SOE Training</h2>
                   <p >
                       After the outbreak of WWII and the German invasion of Norway, Emil returned to the UK, serving in the Royal Navy as a wireless operator for over a year. 
                       In March 1941, a group of Norwegian SOE agents was formed called 'Kompani Linge' to carry out special operations in Norway, which Emil joined a few months later. 
                    </p>
                    <p>   
                       Learn more about the role of a wireless operator in the Royal Navy, including the detection of German U-boats, the training schools that the agents attended, a network so fearsome that the Germans referred to it as 'International Gangster School' and details of Emil's SOE records where he is described as 'Rather outstanding'.
                   </p>


               

                                 
                   <figure>
                    <img alt='Kompani linge agents training in Scotland' src='https://dms-cf-03.dimu.org/image/0231wyLrfZkP?dimension=1200x1200'></img>
                    <figcaption>Kompani Linge agents training in Scotland. </figcaption>
                     <p>(Owner: The Norwegian Industrial Workers' Museum Foundation, <a href='https://creativecommons-org.translate.goog/licenses/by-nc/4.0/deed.en?_x_tr_sl=no&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=sc' aria-label='licence'>licence</a>)</p>

                   </figure>
                  </div>
  
                 
                   <div className=' red link info'>
                    <h2 className='top'>The Televag Tragedy</h2>
                      <p >
                        In April 1942, Emil and a fellow agent Arne Vaerum, travelled in a small fishing boat across the North Sea, from Scotland to the coast of western Norway. 
                        Once there they would carry out seperate operations in the country before they came back to the UK, however they never did return. 
                          On 26th April, the Televag Tragedy occurred, resulting in the village being destroyed by the Nazis and the villagers sent to concentration camps. 
                          This section briefly explains the background and details to this tragic event.
                      </p>


                  

                    
                      <figure>
                          <img alt='buildings being destroyed in televag' src='https://api.fanger.no/files/31682a39-f659-466e-8b93-b1c0480c7a77.jpg'></img>
                          <figcaption>Buildings being destroyed in Televag</figcaption>
                          <p>(Owner: Nordsjøfartmuseet)</p>
                      </figure>
                   </div>
 

                   <div className='blue link info'>
                    <h2 className='top'>POW and War Memorials</h2>
                      <p >
                          After being captured by the Nazis, Emil was tortured and interrogated, and kept as a POW in concentration camps.  
                          He was later taken to a forest, Trandumskogen, with other resistance fighters where they were executed and buried in a mass grave.
                          After the war, many memorials have been built in honour of the people who served and lost their lives during WWII. 
                          This section includes information about these memorials, a translation of the letter Emil was able to write to his family whilst a POW, and details of the conditions the prisoners faced at the concentration camps.  
                      </p>


                

                                  
                      <figure>
                          <img alt='trandumskogen memorial' className='portrait' src='https://live.staticflickr.com/2362/3532066442_067757377a_c.jpg'></img>
                          <figcaption>Trandumskogen Memorial</figcaption>
                          <p>(Owner: Fredrik Olastuen, <a href='https://creativecommons.org/licenses/by-nc/2.0/' aria-label='licence'>licence</a>)</p>
                      </figure>
                   </div>

                   <div className='red link info'>
                    <h2 className='top'>My Father's Story</h2>              

                                  
                      <figure>
                          <img alt='trandumskogen memorial' src='https://live.staticflickr.com/65535/52124057144_3705d67ef2.jpg'></img>
                          <figcaption>My Father reading a letter that Emil wrote for the first time</figcaption>
                          <p>(Owner: Ostlands-Posten)</p>
                      </figure>
                   </div>

               

                  <a className='home next-button' href='/early-life-and-whaling'>Next Page</a>

     
            </div>

        )
    }

export default Home ;