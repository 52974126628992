import React from "react";
import { Link } from 'react-router-dom';

const Televag = () => {

          return(
            <div>
                
                <div className='blue top info'>
                 <h2>The Televag Tragedy</h2> 
                <p>
                        After his training at Grendon Hall, Emil was due to be part of operation Anchor with agent Tor Gulbrandsen. 
                        The operation would set up radio communications back to the UK and train local resistance in Norway in weapons and explosives.
                        They were both due to be parachuted into Drammen, western Norway, in January 1942.
                        However, there was a miscommunication relating to a previous operation, which led the Anchor agents to believe that the previous operation had landed the night before at the Anchor team's drop off point in Drammen. 
                        This was not standard practice and they called off their flight due to this and the weather, causing their plans for reaching Norway to be changed.
                        <sup><a href='#secretalliances'>[1]</a></sup>
                        
                  </p>
                  <p>
                  During the start of the war, several fishing boats made journeys across the North Sea from the coast of western Norway to the Shetland Islands carrying Norwegians escaping the German occupation with some looking to join the resistance. 
                  With increasing numbers looking to use this escape route, the SOE soon learned of this and asked the fishermen if they could take SOE agents to Norway on their return to carry out operations there. 
                  This developed into what became know as the 'Shetland Bus'.
                  The crossings would have been done at night to avoid detection and meant the crossings were done in rough seas. 

                  </p>
                  <div className='learn-more'>
                  <p>
                    Read more about the Shetland Bus:
                  </p>
                  <a target='_blank' href='https://coffeeordie.com/shetland-bus-norway-wwii/'>The Shetland Bus: Norway's Only Hope During World War II</a>
                  </div>
                  <figure>
                    <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Shetlandgjengen.jpg/1200px-Shetlandgjengen.jpg?20200904222603'></img>
                    <figcaption>The Shetland Bus Memorial</figcaption>
                    <p>(Owner: Bingar1234, <a href='https://creativecommons.org/licenses/by-sa/4.0/deed.en'>licence</a>)</p>
                  </figure>
                </div>
                <div className='red info'>
                    <p className='top'>
                      It was decided that Emil would travel by the 'Shetland Bus' to get to Norway for the operation.
                      On 17th April 1942, Emil and another agent, Arne Vaerum, who was to take part in operation Penguin, travelled on one of these boats, the <i>Olaf</i>, from the Shetland Islands to Televag, a remote village on the coast of Norway, a journey which would take around 3 days. 
                      There they would stay covertly with a fisherman, Laurits Telle, and his family, including his son Lars Telle, who was also involved in the 'Shetland Bus', before they went on to their respective operations.
                      However, a chain of events would prevent these plans from being carried out. 
                    </p>
                    <p>
                        Laurits Telle had sold one of his boats to a local man, however the man believed that this was defective and that Laurits had tricked him, therefore reporting him to a local policeman, Per Lie. 
                        However Per Lie did not follow up on this compliant, and the man reported Laurits to the local State Police.  
                        </p>
                        <p>
                        On 24th April, Johan Bjorgan from the state police was sent to Televag from nearby Bergen to investigate, however he considered the matter to be rather trivial.
                        At that time, Televag could only be reached by boat, and on the journey there, whilst undercover, received information from the boatman that Laurits Telle was keeping people in his house to travel to Scotland. 
                        After visiting Laurits Telle's house and asking to go on a 'fishing trip', code for going on the 'Shetland Bus', he believed that this was the case.
                        He went back to Per Lie's police station where he reported his findings to Gestapo headquarters in Bergen, not knowing that the people in Telle's house were actually trained agents.   
                        <sup><a href='#gurandsrud'>[2]</a></sup>
                    </p>
                    <figure>
                        <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Telav%C3%A5g.jpg/1200px-Telav%C3%A5g.jpg?20160621215205'></img>
                        <figcaption>Televag</figcaption>
                        <p>(Owner: Cavernia, <a href='https://creativecommons.org/licenses/by-sa/4.0/deed.en'>licence</a>)</p>
                    </figure>
                </div>
                <div className='blue info'>
                  <p className='top'>
                    In the middle of the night on 26th April, a group of Gestapo and state police descended on the house.
                    The following is an account of what happened that night from Åge Telle, who's room the agents were sleeping in, the translation was done by the <a target='_blank' href='https://nordsjofartsmuseum.museumvest.no/english/our-exhibitions/'>North Sea Traffic Museum</a> from audio recordings at the NRK archive: 

                  </p>
                  <div className='italics'>
                  <p>
                  <i>
                    'There were three Germans in civilian clothes who came in with revolvers in their fists and shouted 'Hands up!' using their guns to cover every one of us. 
                    And they ordered the agents to get their clothes on. 
 
                    One of these agents tried to grab his pistol, but he was wrenched out of bed and the Germans hit him, with his pistol probably. 
                    So he fell to the floor.
                    </i>
                    </p>
                    <p>
                    <i>
                    And then he got up and started adjusting his underpants. 
                    He was still in his underwear. 
                    He moved closer and closer to the German, hit him under the chin and grabbed his pistol. 
                    Then straight away the shooting began. 
                    He shot at the Germans who was guarding me. 
                    While he was shooting, the German who was standing on the left bent down, pressed his pistol up under Vaerum's arm and shot, and Vaerum fell down. 
                    </i>
                    </p>
                    <p>
                    <i>
                    But then Hvaal jumped up and began to shoot at the German who had been knocked down. 
                    And then they started shooting at each other, one of them over the table while the German was shooting under the table and up and you could see that Hvaal in any case was hit because red stains started to come through his white underwear.

                    '
                  </i> 
                   </p>
                  </div> 

                  <figure>
                    <img alt='' src='https://live.staticflickr.com/65535/52143211746_3e1c9d77a7_b.jpg'></img>
                    <p>(Owner: The North Sea Traffic Museum)</p>
                  </figure>               
                </div>
                <div className='red info'>
                  <p className='top'>
                    During the incident, Arne Vaerum was killed and Emil had reportedly suffered nine bullet wounds and was captured by the Germans.
                    Two Germans were also killed, SS Hauptsturmfuhrer Gerhard Berns and SS-Untersturmfuhrer Henry Bertram. 
                    </p>
                    <p>
                    Josef Terboven was the Reichskomissar of Norway during the war, and when he found out about what had happened and the death of the two Gestapo men he was enraged and came to Televag to oversee the punishment.
                    </p>
                    <p>
                    On 30th April, the Nazis burnt all the buildings in the village to the ground, sunk all the boats and slaughtered all the cattle.
                    The men of the village, from ages 16 - 65, were forced by Terboven and Nazi security police (SIPO) to watch the destruction of the village from a nearby hill.
                    They were then sent to Sachsenhausen concentration camp in Germany where many would die during the war.
                    The women and children were also interned for the majority of the war. 
                    Twelve villagers including Lauritz and Martha Telle were taken to Bergen to be interrogated and tortured.
                    <sup><a href='#fanger'>[3]</a></sup>
                  </p>
                  <div className='learn-more'>
                  <p>
                    Read more about the Televag tragedy and what happened to the villagers: 
                  </p>
                  <a target='_blank' href='https://nordsjofartsmuseum.museumvest.no/english/'>The North Sea Traffic Museum</a>
                  </div>
                  <p>
                    There is also an app which can be downloaded from their website, which includes many first hand accounts of what the villagers witnessed that day.  
                    The app works best and is designed to be listened to when in Televag to see where the events took place:
                  </p>
                  <div className='learn-more'>
                  <a target='_blank' href='https://nordsjofartsmuseum.museumvest.no/english/our-exhibitions/#Mobile-app-as-a-guide'>Televag 1942</a>
                  </div>
                  <figure>
                    <img alt='' className='portrait' src='https://nordsjofartsmuseum.museumvest.no/media/255813/stega-mot-sachsenhausen.jpg'></img>
                    <figcaption>The villagers being taken away</figcaption>
                    <p>(Owner: The North Sea Traffic Museum)</p>
                  </figure>
                </div>

                <a className='next-button' href='/POW-and-war-memorials'>Next Page</a>
                <div className='blue references'>
                <h2>References</h2>
                <p id='secretalliances'>[1] Tony Insall, 2021, <i>Secret Alliances: Special Operations and Intelligence in Norway 1940-1945 - The British Perspective</i>, Biteback Publishing</p>
                <p id='gurandsrud'> [2] Eirik Gurandsrud, 2005, <i>Televag in time and space. The memory of a war torn fishing community</i>, The University of Bergen</p>
                <p id='fanger'> [3] Kim Bredesen, Inger Fagerberg, <i>The Televag campaign</i>, Fanger.no, viewed June 2022, <a target='_blank' href='https://www.fanger.no/stories/telavaag-aksjonen'>https://www.fanger.no/stories/telavaag-aksjonen</a></p>                
              </div>
            </div>

        )
    }

export default Televag ;