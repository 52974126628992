import React from "react";
import { Link } from 'react-router-dom';

const RNSOE = () => {

          return(
            <div>
              
              <div className='blue top info'>
                <h2>Royal Navy Service and SOE Training</h2>
                <p>
                  Norway tried to remain neutral during the start of WWII, however many events would make this increasingly difficult, including the <a target='_blank' href='https://www.bbc.co.uk/history/ww2peopleswar/stories/72/a4507472.shtml'>Altmark incident</a> , as well as the fact that its coastline and ports were strategically important.
                  On 9th April 1940, Germany launched its invasion of Norway, and after two months they would control the whole of the country.

                </p>
                <div className='learn-more'>
                <p>
                Read more about the German Invasion of Norway:
                </p>
                
                <a target='_blank' href='https://www.royalcourt.no/artikkel.html?tid=28689&sek=27316'>The Royal Court: WWII</a>
                </div>

                <p>
                  During this time, whaling ships were being recquisitioned and many whalers, including Emil, would come to the UK to join the war effort.
                  The records from the Salvesen archives show that the Sourabaya returned to the UK on 3rd May 1940. 
                  The following newspaper article is from the Dundee Evening Telegraph, dated 31/05/1940:  
                </p>
                <h3>5000 Norwegian 'Whalers' Reach Britain</h3>
                <div className='italics'>
                <p>
                <i>
                Five thousand sturdy Norwegian “whalers” who were in the Southern Antarctic seas when they heard of the German invasion of their country are now England, all eager to complete their homeward journey as soon possible to join battle with the Nazis.
                </i>
                </p><p>
                <i>
                In the London hotel where several hundreds are staying the hall is piled with large kitbags, seamen’s chests, and baggage of all description belonging to men who are ready to be off at a moment’s notice.
                </i>
                </p><p>
                <i>                  
                A ship’s engineman to a Press Association reporter voiced the feelings of the Others when he said. “If we can’t get back to Norway and they need us here, we want do our utmost to help—Navy, Army, or Merchant Service —we don’t mind. The Germans are a terrible people.”
                </i>
                </p><p>
                <i>
                 Here a flaxen-haired, middle-aged man broke down completely. It is two year? since he was home—over six months since he had news his wife and children.
                </i>
                
                <sup><a href='#bna'>[2]</a></sup>
                </p>
                </div>
                <p>
                  The building in the article may well have been St. George's Hall in Regent Street, a former theatre recquisitoned for the war. 
                  In the spring of 1940, it had been established as a base for Norwegian sailors, but only until September 1940 as it suffered bomb damage that year and they had to be moved to another hotel.  
                  The Norwegian sailor's would have been well off at the time due to 'danger money', increased wages they had received in the last year of whaling that had increased 3-4 times due to U-boat warfare.
                  This was reportedly spent in the pubs and high end shops of West London. 
                  Charles Ritchie, the Second Secretary at the High Commission in London during the war wrote a diary about his experiences in his book <i>The Siren Years</i>. 
                  The entry for 19th May 1940, mentions Garland's Hotel, which was only 20 minutes walk from St. George's Hall: 

                </p>
                <div className='italics'> 
                <p>
                <i>
                   Refugee's are beginning to arrive from the continent - tough looking Norwegian seamen with shocks of coarse blond hair, dressed in blue serge suits, lunching at Garland's hotel - Dutch peasant girls in native costume like coloured photographs in the Geographical magazine - walking down Cockspur Street carrying their worldly possessions tied up in bundles. 
                </i>
                </p>
                </div>
                <figure>
                  <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/4/45/St_Georges_Langham_Place.jpg?20090928001736'></img>
                  <figcaption>St George's Hall 1867</figcaption>
                </figure>
              </div>
              <div className='red info'>
                <p className='top'>
                  After returning to the UK, Emil joined the Royal Navy as a wireless operator on the 7th June 1940, a role he would serve in for over a year.
                  Throughout this time, the Battle of the Atlantic was at full height, where German U-boats were used to restrict the delivery of goods by sea, which as an island nation, the UK was heavily reliant on.
                  Winston Churchill later wrote that, 'the only thing that really frightened me during the war was the U-boat peril'.
                  
                </p>
                <p>
                  A wireless operator would have been a key role in this battle. 
                  As well as communicating with other ships, they would have been used to intercept the communications between U-boats and their bases which would allow their locations to be detected.
                  They would have been seen as being above other ratings by the wardroom as they would have needed good reaction speeds, dexterity and concentration to do the coding. 
                </p>
                <p>
                  The following is from a first hand account by George Smith, a telegraphist with the Royal Navy during the war: 
                </p>
                <div className='italics'>
                <p>
                <i>
                We were listening and taking down Morse Code from broadcasts. The special work was to identify U-boats (German submarines) from the short message they transmitted on sighting a target vessel or convoy, to their base in Norway. The U-Boat signal was recognised by their special Morse call sign. The message was very short and quick, but just enough to get a bearing on them by a special compass installed in the cabin. I would immediately phone the Captain on the Bridge and tell him the approximate position of the enemy U Boat (he would be completely ignorant of the danger) and then we would contact the senior vessel, by code, to compare the bearings my opposite number had taken, and a third from the other ship. (The three destroyers were Hotspur, Hesperus and Havoc ).The ships would then circle the given location and drop depth charges in the hope of hitting the U-Boat, sometimes successful and sometimes not. The force of these explosions sometimes almost lifted the stern of the vessel out of the water.
</i>
</p>
<p>
<i>
On one apparent hit at a submarine, oil came up and some debris. A terrific cheer went up from those on deck but I was taken aback by this. I knew there were men down there, like us in many ways, and we were killing them. Nevertheless, if the situation was reversed, if they could, they would kill us.
                </i>
                <sup><a href='#swwec'>[1]</a></sup>
                </p>

                </div>
                <div className='learn-more'>
                <p>
                  The link to the full account is below:
                </p>

                
                <a target='_blank' href='https://war-experience.org/lives/george-smith/'>SWWEC: George Smith - RN Telegraphist</a>
                
                <p>
                  There is also further information about the role of a telegraphist in the Royal Navy at the link below:
                </p>
                
                <a  target='_blank' href='http://www.holywellhousepublishing.co.uk/telegraphists.html'>Hollywell House Publishing: 'Sparkers' and 'Bunting Tossers'</a>
                </div>
                <figure>
                  <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Crew_mess_in_HMS_Snapper_WWII_IWM_A_883.jpg/640px-Crew_mess_in_HMS_Snapper_WWII_IWM_A_883.jpg'></img>
                  <figcaption>Crew Mess on A Royal Navy Ship (HMS Snapper) during WWII</figcaption>
                </figure>
              </div>
              <div className='blue info'>
                 <p className='top'>
                    According to Emil's Royal Navy records, he was initially sent to HMS Victory I for just over two months. 
                    This was a training establishment at Queen Street, Portsmouth named after Lord Nelson's flagship HMS Victory at the Battle of Trafalgar, which is located nearby.
                    In September 1940, Emil was then sent to HMS Racer, a shore establishment in Northern Ireland. 
                    The port there would have been strategic for access to the British Isles from the Atlantic around the north of Ireland as the south would have been too dangerous due to the German occupation of France. 
                    At HMS Racer, they would be sending and receiving coded messages from ships on exercise, including frigates escorting merchant ships across the Atlantic. 
                    During his last couple of months in the Navy from May - July 1941, Emil spent the majority of this time in different hospitals, mainly the Seaman's Hospital in Greenwich. 
                    It is unclear from his Royal Navy records why he spent this time in hospital. 

                 </p>
                 
                 <p>
                  Peggy Byatt was a telegraphist for the Royal Navy at HMS Racer during WWII. You can read more about her training and experience in Larne below:
                 </p>
                 <div className='learn-more'>
                 <a target='_blank' href='https://www.bbc.co.uk/history/ww2peopleswar/stories/68/a3424268.shtml'>Training to be a WRNS Wireless Telegraphist and My Posting in N. Ireland</a>
                 </div>
                 <figure>
                  <img alt='' src='https://s0.geograph.org.uk/geophotos/06/74/92/6749219_4fa6696c_original.jpg'></img>
                  <figcaption>Victory Main Gate in front of what was formerly HMS Victory I</figcaption>
                  <p>(Attribution: N. Chadwick, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>licence</a></p>
                 </figure>
              </div>
              <div className='red info'>
                <p className='top'>
                The SOE, Special Operations Executive, was a secret organisation formed in July 1940 from three other organisations, to carry out espionage, sabotage and reconnaissance in occupied Europe during WWII and was known as 'Churchill's Secret Army'.
                Following the invasion of Norway, groups of Norwegians had managed to escape from the country across the North Sea in fishing boats.
                Several of the men from these groups were trained as agents by the SOE to carry out operations in Norway. 
                This would later develop into what became known as Kompani Linge (pronounced 'lin-ger'), lead by Martin Linge, the group forming in March 1941.
                </p>
                <p>
                The following is from the Winter Fortress by writer Neal Bascomb about Norwegian SOE agents who took part in an operation in Norway later in the war to sabotage the Nazis developing an atomic bomb. 
                It describes the meeting between <a target='_blank' href='https://hostfest.com/2020/10/19/knut-haukelid/'> Knut Haukelid</a>, who was looking to join the SOE and Martin Linge. 
                </p>
                <div className='italics'>
                <p>
                <i>
                There, in an attic-floor office overlooking Trafalgar Square,
Haukelid met Martin Linge. Wearing the gray-green uniform of a
Norwegian Army captain, Linge had a winning smile and a firm
handshake, and like every one of Linge’s potential recruits,
Haukelid was charmed by the officer straightaway. A former actor,
Linge had been attached to a British unit when it landed near
Trondheim in April 1940.
</i>
</p>
<p>
<i>
At first, Linge spoke of how things were in Norway, of places
they both knew. There was no doubt he was trying to read
Haukelid: what he wanted, why, and if he was capable of
achieving it. He explained that there was a lot of work to do if
Haukelid had a mind for it. He would be joining a small company,
numbering only a couple of hundred men. It was overseen by the
British but was made up only of Norwegians. Military experience
was helpful, but not necessary, because he would be practicing
unconventional warfare, often behind the lines. It was the kind of
warfare where one man in the right place could make a big
difference. The training would be tough, even brutal, and the
operations even more so. The specifics of these were not made
clear to Haukelid, but he wanted in nonetheless.
<sup><a href='#thewinterfortress'>[3]</a></sup>
                </i>
                </p>
                </div>

                <figure>
                  <img alt='' className='portrait' src='https://upload.wikimedia.org/wikipedia/commons/9/92/Martin_Linge.jpeg'></img>
                  <figcaption>Martin Linge</figcaption>
                  
                </figure>
              </div>
              <div className='blue info'>
                <p className='top'>
                  Emil would join the group a few months later on 24th July 1941. 
                  The agents would be trained at Special Training Schools, or STSs, throughout the UK, carrying out preliminary training which would prepare them for their main training. 
                  Emil carried out his preliminary training at Stodham Park or STS3, a requisitioned country house, near Liss in Hampshire.
                  There the agents would be trained in sabotage, weapons, explosives, Morse code, as well as more physical training. 

                </p>
                <p>
                  The following comes from the Winter Fortress by Neal Bascomb and describes Knut Haukelid's training at Stodham Park:
                </p>
                <div className='italics'>
                <p>
                <i>
                At 6:00 a.m. the squad of new recruits started their day with
what Mix liked to call “hardening of the feet’: a fast march across
the extensive estate. After an hour of this, the recruits’ feet were
more blistered than hardened. A short breakfast was followed by
weapons instruction. “This is your friend,” Mix said, twirling his
pistol around his finger. “The only friend you can rely on. Treat
him properly, and he will take care of you.” Then he took them out
to a grove in the woods and taught them how to stand — knees
bent, two hands on the grip of the pistol — and how to fire: two
shots quickly in a row to make sure the enemy was down. If
circumstances allowed, “Aim low. A bullet in the stomach, and
your German will squirm for twelve hours before dying.” Haukelid
had grown up hunting, but this was something very different.
</i>
</p>
<p>
<i>
After two hours of shooting, they spent another in the gym.
They somersaulted forward and backward. They jumped off high
ledges and rolled forward into a standing position. They
pummeled punching bags. They wrestled, learning how to take
down and disarm an enemy with their bare hands. A long knife
hanging from his hip, Mix wove into his lessons stories of his own
fighting in World War | and his time policing in the Far East. The
other instructors were the same, one sharing, “We killed so many
Germans, we had to rise up on our tiptoes to look over the heaps
of them.”
</i>
</p>
<p>
  <i>
A break for coffee, then the squad had signals training,
learning how to send and receive in Morse code. This was
followed by lunch, then a two-hour class in simple demolition.
“Never smoke while working with explosives,” Mix said, a lit
cigarette perched between his lips, again offering the point that
rules existed to be broken. They blew up logs and sent rocks
skyward. Ears ringing, they moved on to orienteering, navigating
the estate with maps and compasses, then field craft, stalking
targets and scouting routes through the woods. From 5:00 to 8:00
p.m. they were free to relax and have a meal before the night
exercises began. These consisted of more weapons, more
explosives, more unarmed combat — now executed in the pitch
darkness.
<sup><a href='#thewinterfortress'>[3]</a></sup>
                </i>
                </p>
                </div>
                <p>
                  After their preliminary training, agents would be assessed before they proceeded to the more challenging part of their training. 
                  According to his SOE records, on the 14th August, Emil received the following remarks from Major Tynan: 

                </p>
                <div className='italics'>
                <p>
                <i>
                  Very reliable man. 
                  Takes a lot of trouble and has displayed great interest and keenness. 
                  Rather outstanding.
                </i>
                </p>
                </div>
                <div className='italics'>
                <p>
                  As well as the following: 
                </p>
                <p>
                <i>
                  Languages: Good knowledge of English
                </i>
                <br></br>
                <i>
                  Health: Very Good.
                </i>
                <br></br>
                <i>
                  Character: Strong.                  
                </i>
                <br></br>
                <i>
                  Physical Train.: Good
                </i>
                <br></br>
                <i>
                  Weapons: Fair
                </i>
                <br></br>
                <i>
                  Explosives: Good
                </i>
                <br></br>
                <i>
                  Communications: Morse - expert knowledge.
                </i>
                <br></br>
                <i>
                  Map reading: Fair
                </i>
                
                </p>
                </div>
                <figure>
                  <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/4/40/Terrace_at_Stodham_Park_-_geograph.org.uk_-_2195057.jpg?20210619132219'></img>
                  <figcaption>Stodham Park</figcaption>
                  <p>(Attribution: Terrace at Stodham Park by Shazz, <a href='https://creativecommons.org/licenses/by-sa/2.0/deed.en'>licence</a>)</p>
                </figure>               
              </div>
              <div className='red info'>
                <p className='top'>
                  The Kompani Linge agents that had passed the preliminary course would carry out their next stage of training in a location which mirrored the rugged and wild environment of Norway, the Cairngorms.
                  There were several STSs throughout this area of Scotland, which had been former shooting lodges, and agents would normally spend around 4 weeks at one of these.
                  Emil carried out his training at Meoble Lodge, or STS23, starting his course there on 17th August 1941.
                  Amongst the training there, the agents would have been woken early in the morning to go on long runs across moorland and through streams, navigating without a map or compass. 
                  As well as using British and German weapons, and further training in explosives, including blowing up abandoned railway lines.   
                </p>
                <p>
                  The following comes from the Winter Fortress by Neal Bascomb and describes Knut Haukelid's training at Meoble Lodge:
                </p>
                <div className='italics'>
                  <p>
                  <i>
                Marches through Stodham Park were replaced with scrambles
through thick brush, fording ice-cold rivers, and rappelling down
steep ravines — or crossing them on ropes. With both British and
foreign weapons, the men practiced instinctive shooting (shooting
without the use of sights) and learned close-quarter firing. They
stalked the shadowed pine woods, firing at pop-up targets. They
moved through building mockups, clearing the rooms. In
demolitions, they graduated from blowing up logs to destroying
railroad cars and factories. They crafted charges and incendiary
devices of all sizes. Haukelid was amazed at what a small charge
placed in the perfect spot at the perfect time could do: it could
stop an army, render a weapons plant useless.
</i>
</p>
<p>
<i>
They became faster at sending and receiving messages in
Morse. They practiced how to kill silently with a knife, making sure
the blade slipped into flesh, avoiding bone. They were taught how
to break into safes, how to use poison, how to incapacitate
someone with chloroform. They learned how to follow a route to a
target by memory alone, without maps or compass. They studied
how to camouflage themselves in the field, how to crawl through a
marsh and reach their enemy undetected, how to take them down
without a sound — without even a weapon.
<sup><a href='#thewinterfortress'>[3]</a></sup>
</i>
</p>
</div>
                <div className='learn-more'>
                <p>Read more about SOE training in Scotland:</p>
                <a target='_blank' href='https://www.bbc.com/future/article/20170111-the-surprising-place-where-wwii-agents-learnt-to-fight-nazis'>The surprising place where WWII agents learnt to fight Nazis</a>
                <p>
                 Read a preview of the Winter Fortress, Chapter 3 includes details of Knut Haukelid's training:
                </p>                
                <p><a target='_blank' href='https://www.google.co.uk/books/edition/The_Winter_Fortress/gUwoCwAAQBAJ?hl=en&gbpv=1&dq=%27stodham+park%27+war&pg=PT49'>The Winter Fortress: The Epic Mission to Sabotage Hitler's Atomic Bomb - Chapter 3</a>
                </p>

                </div>
                <figure>
                  <img alt='' src='https://s0.geograph.org.uk/geophotos/04/97/20/4972023_07c7aeff_original.jpg'></img>
                  <figcaption>Meoble</figcaption>
                  <p>(Owner: Richard Webb, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>licence</a>)</p>
                </figure>
              </div>
              <div className='blue info'>
                <p className='top'>
                Throughout the war, wireless operators were in high demand as the Germans were effective at finding them and the average life time of an operator in the field would be less than a couple of months.
                Due to his knowledge in this area, Emil was selected to attend a specialist school in communications.
                Grendon Hall, also known as STS53 or Station 53, near Grendon Underwood in Buckinghamshire, was a converted manor house, used during the war as a radio station to communicate with the agents in occupied Europe.
                Conveniently located near to the SOE HQ in Baker Street, London, and Bletchley Park, it also offered a good level of security and secrecy.
                </p>
                <p>
                Emil went for training there on 13th September 1941, staying there until the end of January 1942.
                The training included the trainees communicating back to base from homes in the area, the trainers arranging secret meetings in cafes to provide messages to send, and trainers bursting into the agents rooms in the middle of the night to check if their radio sets were secure, preparing them for potential Gestapo raids whilst on operations.
                <sup><a href='#secretwarheroes'>[4]</a></sup>
                </p>
                <p>
                As well as the trainee agents, another group working at Grendon Hall at the time were the First Aid Nursing Yeomanry, a women's volunteer organisation that worked in nursing and intelligence during the war.
                D Day Girls by Sarah Rose is a book about women who worked as spies during WWII and the following chapter describes the work that they and the agents would have done at Grendon Hall:

                </p>
                <div className='learn-more'>
                <a target='_blank' href='https://www.google.co.uk/books/edition/D_Day_Girls/rKKODwAAQBAJ?hl=en&gbpv=1&dq=d+day+girls+chapter+12+our+possibilities+paris&pg=PT110'>D Day Girls - Chapter 12: Our Possibilities</a>
                </div>
                <p>
                  Covert Radio Agents 1939 - 1945 by David Hebditch is a book about agents who worked as wireless operators during the war, with details about the operations they were involved with. 
                  The following is a link to the introduction which briefly explains how they would have worked whilst on operation:
                  
                </p>
                <div className='learn-more'>
                <a target='_blank' href='https://www.google.co.uk/books/edition/Covert_Radio_Agents_1939_1945/XKMeEAAAQBAJ?hl=en&gbpv=1&dq=covert+radio+agents+signals+from+behind+enemy+lines+introduction+unsung+heroes&pg=PR7'>Covert Radio Agents 1939 - 1945 - Introduction: Unsung Heroes?</a>
               </div>
               <figure>
                  <img alt='' src='https://upload.wikimedia.org/wikipedia/commons/0/0e/HMP_Grendon%2C_Bucks_-_crop.jpg?20100812172420'></img>
                  <figcaption>Grendon Hall</figcaption>
                  <p>(Owner: Andy Gryce, <a href='https://creativecommons.org/licenses/by-sa/3.0/deed.en'>licence</a>)</p>
                </figure>
              </div>
              <a className='next-button' href='/Televag'>Next Page</a>
              <div className='red references'>
                <h2>References</h2>
                <p id='swwec'>
                  [1] Second World War Experience Centre, George Smith - RN Telegraphist (s), viewed June 2022, https://war-experience.org/lives/george-smith/
                 </p> 
                <p id='bna'>
                  [2] The British Newspaper Archive (www.britishnewspaperarchive.co.uk); The British Library Board, Image © D.C.Thomson & Co. Ltd. Image created courtesy of THE BRITISH LIBRARY BOARD.
                </p> 
                <p id='thewinterfortress'>
                  [3] Neal Bascomb, 2016, <i> The Winter Fortress: The Epic Mission to Sabotage Hitler's Atomic Bomb</i>, Head of Zeus
                </p>               
                <p id='secretwarheroes'> [4] Marcus Binney, E 2012, <i>Secret War Heroes</i>, Hodder & Stoughton</p>

              </div>
            </div>

        )
    }

export default RNSOE ;