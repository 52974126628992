import React from "react";
import './footer.css'; 

const Footer = () => {

          return(
              <div className='blue footer'>
                <p className='developer'>Hvaal Web Solutions - 2022</p>
             </div>

        )
    }

export default Footer ;